import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Aguardando from '../common/template/aguardando';
import { MapLeaflet } from "../common/mapLeaflet/MapLeaflet";
import Cookies from 'js-cookie';
import consts from '../consts';
import iconeBandeira from '../assets/images/icone_bandeira.png';
import iconeViagemAberta from '../assets/images/icone_viagem_aberta.png';
import iconeRelogio from '../assets/images/icone_relogio.png';

import { 
	setModoTela, setFiltro, getListaFronteiras, getListaNegociadores, getListaOperacoes, getLista, getListaOcorrencias
} from './reportsClienteActions';
import { registrarAcesso } from '../analiseAcesso/analiseAcessoActions';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import SelectNovo from '../common/form/selectNovo';
import SwitchVertical from '../common/form/switchVertical';

class ReportsCliente extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		pesquisarAux: '',
		idShippingExpandido: null,
		resumido: true,
		registroModal: null,
		registroModalOcorrencia: null,
		listaFavoritos: JSON.parse(Cookies.get('shippingsFavoritos') || '[]'),
		listaPeriodo: [
			{ id: 'TODOS', valor: 'Todos' },
			// { id: 'DATA_PREVISAO_CARGA', valor: 'Previsão Carga' },
			{ id: 'DATA_INICIO_CARGA', valor: 'Chegada Carga' },
			{ id: 'DATA_TERMINO_CARGA', valor: 'Saída Carga' },
			// { id: 'DATA_ENTRADA_ADUANA_ORIGEM', valor: 'Chegada Alfândega Origem' },
			// { id: 'DATA_LIBERACAO_ADUANA_ORIGEM', valor: 'Liberação Alfândega Origem' },
			// { id: 'DATA_PREV_FRONTEIRA', valor: 'Previsão Fronteira' },
			{ id: 'DATA_ENTRADA_FRONTEIRA', valor: 'Chegada Fronteira' },
			// { id: 'DATA_AUTORIZACAO_INGRESSO_FRONTEIRA', valor: 'Autorização Ingresso fronteira' },
			// { id: 'DT_ENTRADA_ADUANA_FRONT_ORG', valor: 'Chegada Alfândega Fronteira Origem' },
			// { id: 'DT_LIBERACAO_ADUANA_FRONT_ORG', valor: 'Liberação Alfândega Fronteira Origem' },
			// { id: 'DT_ENTRADA_ADUANA_FRONT_DEST', valor: 'Chegada Alfândega Fronteira Destino' },
			// { id: 'DT_LIBERACAO_ADUANA_FRONT_DEST', valor: 'Liberação Alfândega Fronteira Destino' },
			{ id: 'DATA_LIBERACAO_FRONTEIRA', valor: 'Liberação Fronteira	' },
			// { id: 'DATA_ENTRADA_ADUANA_DESTINO', valor: 'Chegada Alfândega Destino' },
			// { id: 'DATA_LIBERACAO_ADUANA_DESTINO', valor: 'Liberação Alfândega Destino' },
			// { id: 'DATA_PREVISAO_DESCARGA', valor: 'Previsão Descarga' },
			{ id: 'DATA_INICIO_DESCARGA', valor: 'Chegada Descarga' },
			{ id: 'DATA_TERMINO_DESCARGA', valor: 'Saída Descarga' }
		],
		listaOrdenacao: [
			{ id: 'solicitacao', valor: 'Solicitação de Carga' },
			{ id: 'cavalo_carreta', valor: 'Cavalo / Carreta' },
			{ id: 'carga', valor: 'Carga' },
			{ id: 'fronteira', valor: 'Fronteira' },
			{ id: 'descarga', valor: 'Descarga' }
		],
		filtro: {
			tipo_periodo: 'DATA_INICIO_CARGA',
			data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(31)),
			data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
			aberto: true,
			finalizado: false,
			semNaoCliente: false,
			comNaoCliente: true,
			/*rumos: [],
			farolAtrasos: [],
			fronteiras: [],
			operacoes: [],
			negociadores: [],
			lote: false,
			classificada: false,
			expressa: false,			
			ocorrenciaAberta: false,
			cavaloEngatado: false,
			somenteCarreta: false,
			semConjunto: false,
			emCD: false,
			semDocumento: false,*/
			nacional: this.props.usuarioCarregado ? this.props.usuarioCarregado.nacional : false,
			internacional: this.props.usuarioCarregado ? this.props.usuarioCarregado.internacional : false,
			ocorrencia_aberta: false,
			transit_time_atraso: false
		},
		ordenacao: Cookies.get('reportsClienteOrdenacao') || 'solicitacao'
	}
	
    componentWillMount() {
		this.props.getListaFronteiras();
		this.props.getListaNegociadores();
		this.props.getListaOperacoes();
        this.props.getLista(this.state.filtro);

		setInterval(() => {
			this.props.getListaFronteiras();
			this.props.getListaNegociadores();
			this.props.getListaOperacoes();
			this.props.getLista(this.state.filtro);
		}, 120000)
    }

	componentDidMount() {
		//Início do registro de Análise Tela
		this.timeEntrouTela = Date.now();
		this.idEntrouTela = setInterval(() => {
			this.props.registrarAcesso('App Cliente', this.props.location.pathname, Date.now() - this.timeEntrouTela);
			this.timeEntrouTela = Date.now();
		}, 60000);
		//Fim do registro de Análise Tela
	}
	
	componentWillUnmount() {
		//Início do encerramento de Análise Tela
		clearInterval(this.idEntrouTela);
		this.props.registrarAcesso('App Cliente', this.props.location.pathname, Date.now() - this.timeEntrouTela);
		//Fim do encerramento de Análise Tela
	}

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.montarTela()}

                </Content>
            </div>
        )
    }

	filtrar() {

		let acessoCliente = this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente;

		let lista = this.props.lista

			.filter(item => {
				if (this.props.usuarioCarregado && (this.props.usuarioCarregado.placas_cco || []).length > 0) {
					return (this.props.usuarioCarregado.placas_cco || []).filter(placa => placa == item.cavalo).length > 0;
				} else {
					return this.props.usuarioCarregado ? true : false;
				}
			})

			.filter(item => {
				if (this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente) {
					return (this.props.usuarioCarregado.id_negociadores || []).filter(negociador => negociador == item.id_negociador).length > 0;
				} else {
					return true;
				}
			})

			//FILTROS SELECT
			.filter(item => {

				/*if ((this.state.filtro.rumos || []).length && !this.state.filtro.rumos.filter(itemFiltro => itemFiltro == item.rumo).length) {
					return false;
				}
				if ((this.state.filtro.farolAtrasos || []).length 
					&& !this.state.filtro.farolAtrasos.filter(itemFiltro => 
						itemFiltro == 'ADIANTADO' && item.status == 1
						|| itemFiltro == 'ATRASADO' && (item.status == 2 || item.status == 3)
						|| itemFiltro == 'NENHUM' && !item.status
					).length) {
					return false;
				}
				if ((this.state.filtro.fronteiras || []).length && !this.state.filtro.fronteiras.filter(itemFiltro => itemFiltro == item.id_fronteira_ref).length) {
					return false;
				}
				if ((this.state.filtro.operacoes || []).length 
					&& !this.state.filtro.operacoes.filter(itemFiltro => 
						(this.props.listaOperacoes.filter(operacao => operacao.id == itemFiltro)[0] ? this.props.listaOperacoes.filter(operacao => operacao.id == itemFiltro)[0].valor : null)
						 == item.descricao_operacao).length) {
					return false;
				}
				if ((this.state.filtro.negociadores || []).length && !this.state.filtro.negociadores.filter(itemFiltro => itemFiltro == item.id_negociador).length) {
					return false;
				}*/

				return true;
			})

			//FILTROS SWITCH
			.filter(item => {

				if (!this.state.filtro.aberto || !this.state.filtro.finalizado) {
					if (!this.state.filtro.aberto && !this.state.filtro.finalizado) {
						return false;
					} else if (this.state.filtro.aberto && item.finalizado) {
						return false;
					} else if (this.state.filtro.finalizado && !item.finalizado) {
						return false;
					}					
				}
				
				/*
				if (this.state.filtro.lote && !item.lote) {
					return false;
				}
				if (this.state.filtro.classificada && !item.classificada) {
					return false;
				}
				if (this.state.filtro.expressa && !item.expressa) {
					return false;
				}
				if (this.state.filtro.ocorrenciaAberta && !item.ocorrencias_abertas) {
					return false;
				}
				if (this.state.filtro.cavaloEngatado && !item.cavalo) {
					return false;
				}
				if (this.state.filtro.somenteCarreta && !item.carreta) {
					return false;
				}
				if (this.state.filtro.semConjunto && (item.cavalo || item.carreta)) {
					return false;
				}
				if (this.state.filtro.semDocumento && item.qtd_documentos > 0) {
					return false;
				}*/
				if (!this.state.filtro.nacional || !this.state.filtro.internacional) {
					//Se não tem rumo significa que ainda não foram registrados eventos no shipping
					if (!this.state.filtro.nacional && !this.state.filtro.internacional) {
						return item.rumo == 'INDEFINIDO';
					}
					if (this.state.filtro.nacional && item.rumo != 'NAC') {
						return false;
					}
					if (this.state.filtro.internacional && item.rumo == 'NAC') {
						return false;
					}
				}
				
				if (this.state.filtro.ocorrencia_aberta && item.ocorrencias_abertas <= 0) {
					return false;
				}


				let transitTimeAtraso = false;
				if (item.transit_time < 100 && item.datahora_previsao_real) {
					if (!item.data_inicio_carga) {
						if (item.data_previsao_carga && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_previsao_carga).getTime()) {
							transitTimeAtraso = true;
						}
					} else if (item.fronteira && item.data_termino_carga && !item.data_entrada_fronteira) {
						if (item.data_prev_fronteira && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_prev_fronteira).getTime()) {
							transitTimeAtraso = true;
						}
					} else if (((!item.fronteira && item.data_termino_carga) || (item.fronteira && item.data_liberacao_fronteira)) && !item.data_inicio_descarga) {
						if (item.data_previsao_descarga && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_previsao_descarga).getTime()) {
							transitTimeAtraso = true;
						}
					}
				}

				if (this.state.filtro.transit_time_atraso && !transitTimeAtraso) {
					return false;
				}
				
				return true;

			})
			//FILTRO BUSCA TEXTO
			.filter(item => {

				return !(this.state.pesquisar || '').trim()
					|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {
					if (pesquisar
						&& !(`
							${item.shipping_code_id || ''}
							${item.solicitacao_carga_id || ''}
							${item.cavalo || ''}
							${item.carreta || ''}
						`).toUpperCase().includes(pesquisar)) {
						return false;
					}

					return true;

				}).length > 0;

			});

			if (this.state.ordenacao == 'solicitacao') {
				lista.sort((a, b) => parseInt(a.solicitacao_carga_id) > parseInt(b.solicitacao_carga_id) ? 1 : -1);
			} else if (this.state.ordenacao == 'cavalo_carreta') {
				lista.sort((a, b) => `${(a.cavalo || '')}-${(a.carreta || '')}` > `${(b.cavalo || '')}-${(b.carreta || '')}` ? 1 : -1);
			} else if (this.state.ordenacao == 'carga') {
				lista.sort((a, b) => new Date(a.data_inicio_carga || null) > new Date(b.data_inicio_carga || null) ? 1 : -1);
			} else if (this.state.ordenacao == 'fronteira') {
				lista.sort((a, b) => new Date(a.data_entrada_fronteira || null) > new Date(b.data_entrada_fronteira || null) ? 1 : -1);
			} else if (this.state.ordenacao == 'descarga') {
				lista.sort((a, b) => new Date(a.data_inicio_descarga || null) > new Date(b.data_inicio_descarga || null) ? 1 : -1);
			}

			return lista;
	}

	getNovaOrdenacao(atual) {
		return atual == 2 ? 0 : (atual || 0) + 1;
	}

	getIconeOrdenacao(tipo) {
		if (tipo == 1) {
			return <i class="fas fa-sort-amount-down-alt" />;
		} else if (tipo == 2) {
			return <i class="fas fa-sort-amount-up" />;
		} else {
			return null;
		}
	}

	montarTela() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let acessoCliente = this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente;
		let indicadores = this.props.usuarioCarregado && (!this.props.usuarioCarregado.acesso_cliente || this.props.usuarioCarregado.track_indicadores);

		let listaFiltrada = this.filtrar();

		const telaMobile = window.innerWidth < 1247;
		return (
			<ContentCard>
				
				{this.state.modalFiltro ? this.modalFiltro() : null}
				
				{this.state.registroModal ? this.modalSolicitacao() : null}

				<ContentCardHeader paddingBottom={0}>
					
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
						<a className='text-muted' href='#' title='Abrir filtro' 
							onClick={event => {
								event.preventDefault();
								this.setState({
									...this.state,
									modalFiltro: !this.state.modalFiltro
								});
							}} >
							<i className='fas fa-filter' style={{ color: '#1F3066' }}></i>
						</a>
						
						{this.props.usuarioCarregado && this.props.usuarioCarregado.logo &&
						<img src={`${consts.URL}/${this.props.usuarioCarregado.logo}`} style={{ marginBottom: 2, height: 20 }} />}

						<a className='text-muted' href='#' 
							title={this.state.resumido ? 'Mostrar Completo' : 'Mostrar Resumido'}
							onClick={event => {
								event.preventDefault();
								this.setState({
									...this.state,
									resumido: !this.state.resumido
								});
							}} >
							<i className={this.state.resumido ? 'fas fa-expand' : 'fas fa-compress'} style={{ color: '#1F3066' }}></i>
						</a>
					</div>
				</ContentCardHeader>
				<ContentCardBody paddingTop={8}>
					<div 
						style={{ 
							marginTop: -8,
							marginLeft: -8,
							marginRight: -8,
							marginBottom: -8,
							height: `${window.innerHeight - 135}px`
						}}>
						<Table responsive border striped alturaFixa 
								maxHeight={`${window.innerHeight - 135}px`}>
							<THead>

								<Tr backgroundColor={'#1F3066'} color={'#ffffff'}>

									<Th paddingTop={2} paddingBottom={2} sticky minWidth={35} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter fontSize={10} title='Solicitação / Shipping'>
										Sol.
										<br />
										SC
									</Th>
									<Th paddingTop={2} paddingBottom={2} sticky minWidth={45} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter fontSize={10}>
										Cavalo
										<br />
										Carreta
									</Th>
									<Th paddingTop={2} paddingBottom={2} sticky minWidth={80} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter fontSize={10}>
										Carga
									</Th>
									{this.state.filtro.internacional &&
									<Th paddingTop={2} paddingBottom={2} sticky minWidth={80} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter fontSize={10}>
										Fronteira
									</Th>}
									<Th paddingTop={2} paddingBottom={2} sticky minWidth={80} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter fontSize={10}>
										Descarga
									</Th>									
								</Tr>
							</THead>
							<TBody>
								{listaFiltrada.map((item, i) => {
												
									return (
										<Tr 
											backgroundColor={this.state.registroModal && this.state.registroModal.solicitacao_carga_id == item.solicitacao_carga_id ? '#d4d4d4' : (
												i % 2 == 0 ? '#ffffff' : '#f5f5f5'
											)}
											onClick={() => {
												this.setState({
													...this.state,
													registroModal: { ...item }
												});
											}} >
											<Td color={'#000'} alignCenter smallMin>
												{item.solicitacao_carga_id}
												<br />
												{item.shipping_code_id}
											</Td>
											<Td color={'#000'} alignCenter smallMin>
												{item.cavalo || '-'}
												<br />												
												{item.carreta || '-'}
											</Td>
											{this.state.resumido ? (
												<Td color={'#000'} alignCenter>
													{item.data_termino_carga ? (
														<i class='fas fa-check-double' style={{ color: '#1dbf11' }} />
													) : (item.data_inicio_carga ? (
														<i class='fas fa-check' style={{ color: '#118c08' }} />
													) : (
														<i class='fas fa-times' style={{ color: '#E00914' }} />
													))}
												</Td>
											) : (
												<Td color={'#000'} alignCenter smallMin>
													<>
														{DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_carga) || '-'}
														<br />
														{DateFormat.formatarDataHoraSqlParaTela(item.data_termino_carga) || '-'}
													</>
												</Td>											
											)}
											{this.state.filtro.internacional &&
											<>
												{this.state.resumido ? (
													<Td color={'#000'} alignCenter>
														{item.rumo == 'NAC' ? (
															<i class='fas fa-minus' style={{ color: '#000000' }} />
														) : (item.data_liberacao_fronteira ? (
															<i class='fas fa-check-double' style={{ color: '#1dbf11' }} />
														) : (item.data_entrada_fronteira ? (
															<i class='fas fa-check' style={{ color: '#118c08' }} />
														) : (
															<i class='fas fa-times' style={{ color: '#E00914' }} />
														)))}
													</Td>
												) : (
													<Td color={'#000'} alignCenter smallMin>
														{item.rumo == 'NAC' ? null : (
															<>
																{DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_fronteira) || '-'} 
																<br />
																{DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_fronteira) || '-'}
															</>
														)}
													</Td>
												)}
											</>}
											{this.state.resumido ? (
												<Td color={'#000'} alignCenter>
													{item.data_termino_descarga ? (
														<i class='fas fa-check-double' style={{ color: '#1dbf11' }} />
													) : (item.data_inicio_descarga ? (
														<i class='fas fa-check' style={{ color: '#118c08' }} />
													) : (
														<i class='fas fa-times' style={{ color: '#E00914' }} />
													))}
												</Td>
											) : (
												<Td color={'#000'} alignCenter smallMin>
													<>
														{DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_descarga) || '-'}
														<br />
														{DateFormat.formatarDataHoraSqlParaTela(item.data_termino_descarga) || '-'}
													</>
												</Td>
											)}
										</Tr>
									)
								})}
							</TBody>
						</Table>
					</div>
				</ContentCardBody>
				<div className='card-footer' style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 8, paddingRight: 8 }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<div style={{ fontStyle: 'italic', fontSize: 11 }}>
							<div>
								<i class='fas fa-times' style={{ color: '#E00914' }} /> Pendente
								<i class='fas fa-check' style={{ color: '#118c08', marginLeft: 12 }} /> Chegada
							</div>
							<div>
								<i class='fas fa-check-double' style={{ color: '#1dbf11' }} /> Finalizada
								<i class='fas fa-minus' style={{ color: '#000000', marginLeft: 6 }} /> Não se Aplica
							</div>
						</div>
						<div style={{display: 'flex', flexDirection: 'row' }}>
							<div style={{ fontSize: 12, fontStyle: 'italic', marginRight: 4 }}>Total de Solicitações:</div>
							<div style={{ fontSize: 12, fontStyle: 'italic', fontWeight: 'bold' }}>{listaFiltrada.length}</div>
						</div>
					</div>
				</div>
			</ContentCard>
		);
	}

	modalFiltro() {

		return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>Filtro</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, modalFiltro: false })}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ overflowY: 'scroll' }}>

							<Row>					
								<Grid cols='12 12 12 12'>
									<div class='form-group'>
										<label htmlFor='pesquisa_geral'>Pesquisar</label>
										<div class='input-group' id='pesquisar'>
											<input 
												type='text'
												name='pesquisa_geral'
												class='form-control datetimepicker-input'
												data-target='#pesquisar'
												placeholder='Adicione + para combinar pesquisas'
												value={this.state.pesquisarAux}
												onChange={e => {
													this.setState({ ...this.state, pesquisarAux: e.target.value });
												}}
												onKeyDown={e => {
													if (e.key === 'Enter') {
														this.setState({ ...this.state, pesquisar: this.state.pesquisarAux });
													}
												}} />
											<div type='button' class='input-group-append' data-target='#pesquisar'>
												<div 
													class='input-group-text'
													onClick={e => {
														this.setState({ ...this.state, pesquisar: this.state.pesquisarAux });
													}}>
													<i class='fas fa-search'></i></div>
											</div>
										</div>
									</div>
								</Grid>											
								
								<SelectDefault
									label='Período'
									cols='12 12 12 12'
									zIndex={2}
									options={this.state.listaPeriodo}
									placeholder='Todos'
									naoLimpar
									semOpcaoVazia
									value={this.state.filtro.tipo_periodo}
									onChange={data => {
										this.setState({
											...this.state,
											filtro: {
												...this.state.filtro,
												tipo_periodo: data.target.value
											}
										});
									}} />

								<LabelAndInputMask
									label='Data Inicial' placeholder='Informe a data'
									cols='6 6 6 6'
									maxWidth={'50%'}
									mask='99/99/9999'
									inputMode='numeric'
									value={this.state.filtro.data_inicial}
									onChange={(data) => {
										this.setState({
											...this.state,
											filtro: {
												...this.state.filtro,
												data_inicial: data.target.value
											}
										});
									}} />

								<LabelAndInputMask
									label='Data Final' placeholder='Informe a data'
									cols='6 6 6 6'
									maxWidth={'50%'}
									mask='99/99/9999'
									inputMode='numeric'
									value={this.state.filtro.data_final}
									onChange={(data) => {
										this.setState({
											...this.state,
											filtro: {
												...this.state.filtro,
												data_final: data.target.value
											}
										});
									}} />
									
								<SelectDefault
									label='Ordenação'
									cols='12 12 12 12'
									zIndex={2}
									options={this.state.listaOrdenacao.filter(item => item.valor != 'fronteira' || (this.props.usuarioCarregado && !this.props.usuarioCarregado.internacional))}
									placeholder='Selecione'
									naoLimpar
									semOpcaoVazia
									value={this.state.ordenacao}
									onChange={data => {
										this.setState({
											...this.state,
											ordenacao: data.target.value || 'solicitacao'
										});
										Cookies.set('reportsClienteOrdenacao', data.target.value || 'solicitacao', { expires: 60 });
									}} />

									<div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '100%', height: 30, marginTop: 0 }}>
										<div style={{ display: 'flex', alignItems: 'center', marginRight: 8  }} title='Nacionais'>
											<img src={iconeBandeira} style={{ marginRight: 4, width: 18, height: 18 }}></img>											
											<div class="custom-control custom-switch">
												<input type="checkbox" class='custom-control-input' id='customSwitch1'
													checked={this.state.filtro.nacional}
													disabled={!this.props.usuarioCarregado || !this.props.usuarioCarregado.nacional}
													onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, nacional: !this.state.filtro.nacional }})} />
												<label class="custom-control-label" for="customSwitch1"></label>
											</div>
										</div>

										<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Internacionais'>
											<i class='fas fa-globe-americas' style={{ marginRight: 4, width: 18 }}></i>
											<div class="custom-control custom-switch">
												<input type="checkbox" class='custom-control-input' id='customSwitch2'
													checked={this.state.filtro.internacional}
													disabled={!this.props.usuarioCarregado || !this.props.usuarioCarregado.internacional}
													onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, internacional: this.props.usuarioCarregado.internacional && !this.state.filtro.internacional }})} />
												<label class="custom-control-label" for="customSwitch2"></label>
											</div>
										</div>

										<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Abertos'>
											<img src={iconeViagemAberta} style={{ marginRight: 4, width: 18, height: 20 }}></img>
											<div class="custom-control custom-switch">
												<input type="checkbox" class='custom-control-input' id='customSwitch3'
													checked={this.state.filtro.aberto}
													onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, aberto: !this.state.filtro.aberto }})} />
												<label class="custom-control-label" for="customSwitch3"></label>
											</div>
										</div>

										<div style={{ display: 'flex', alignItems: 'center' }} title='Finalizados'>
											<i class='fas fa-flag-checkered' style={{ marginRight: 4, width: 18 }}></i>
											<div class="custom-control custom-switch">
												<input type="checkbox" class='custom-control-input' id='customSwitch4'
													checked={this.state.filtro.finalizado}
													onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, finalizado: !this.state.filtro.finalizado }})} />
												<label class="custom-control-label" for="customSwitch4"></label>
											</div>
										</div>
									</div>
									
							</Row>

						</div>
						<div class='modal-footer justify-content-center'>
							<button type='button' class='btn btn-primary' style={{ width: 200 }}
								onClick={() => {
									this.setState({ ...this.state, modalFiltro: false, pesquisar: this.state.pesquisarAux });
									this.props.getLista(this.state.filtro);
								}}>Atualizar</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
	
	modalSolicitacao() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h5 class='modal-title'>Detalhes</h5>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, registroModal: null })}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' 
							style={{
								height: window.innerWidth < 576 ? window.innerHeight - 85 : window.innerHeight * 0.75,
								overflowY: 'scroll'
							}}>
						
							<p style={{ fontSize: 14, fontWeight: 'bold', backgroundColor: '#1F3066', color: '#fff', margin: 0, padding: 4, marginTop: 0, marginBottom: 8 }}>Informações da Viagem</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Solicitação Carga:</b> {this.state.registroModal.solicitacao_carga_id}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Shipping Code:</b> {this.state.registroModal.shipping_code_id}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Último Romaneio:</b> {this.state.registroModal.romaneio_atual}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Documento:</b> {this.state.registroModal.documento}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Referência:</b> {this.state.registroModal.observacao}</p>						
							<p style={{ fontSize: 12, margin: 0 }}><b>Fat. Comercial / NF:</b> {this.state.registroModal.fatura}</p>			
							<p style={{ fontSize: 12, margin: 0 }}><b>Peso / Quantidade:</b> {FormatUtils.formatarValorTelaDecimal(this.state.registroModal.documento_peso, 2)} / {FormatUtils.formatarValorTelaDecimal(this.state.registroModal.documento_volumes, 2)}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Remetente:</b> {this.state.registroModal.nome_remetente_sol}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Destinatário:</b> {this.state.registroModal.nome_destinatario_sol}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Origem:</b> {this.state.registroModal.municipio_origem_sol}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Destino:</b> {this.state.registroModal.municipio_destino_sol}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Rota:</b> {this.state.registroModal.rota}</p>
							{this.state.registroModal.rumo != 'NAC' &&
							<p style={{ fontSize: 12, margin: 0 }}><b>Aduana:</b> {this.state.registroModal.aduana}</p>}
							{this.state.registroModal.rumo != 'NAC' &&
							<p style={{ fontSize: 12, margin: 0 }}><b>Fonteira:</b> {this.state.registroModal.nome_fronteira}</p>}

							<p style={{ fontSize: 14, fontWeight: 'bold', backgroundColor: '#1F3066', color: '#fff', margin: 0, padding: 4, marginTop: 8, marginBottom: 8 }}>Eventos</p>

							<p style={{ fontSize: 12, margin: 0 }}><b>Previsão Carga</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_previsao_carga)}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Chegada Carga</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_inicio_carga)}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Saída Carga</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_termino_carga)}</p>
							
							{this.state.registroModal.rumo != 'NAC' &&
							<>
								<p style={{ fontSize: 12, margin: 0 }}><b>Chegada Alf. Origem</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_entrada_aduana_origem)}</p>
								<p style={{ fontSize: 12, margin: 0 }}><b>Liberação Al. Origem</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_liberacao_aduana_origem)}</p>
								<p style={{ fontSize: 12, margin: 0 }}><b>Previsão Fronteira</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_prev_fronteira)}</p>
								<p style={{ fontSize: 12, margin: 0 }}><b>Chegada Fronteira</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_entrada_fronteira)}</p>
								<p style={{ fontSize: 12, margin: 0 }}><b>Aut. Ingresso fronteira</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_autorizacao_ingresso_fronteira)}</p>
								<p style={{ fontSize: 12, margin: 0 }}><b>Chegada Alf. Front. Origem</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.dt_entrada_aduana_front_org)}</p>
								<p style={{ fontSize: 12, margin: 0 }}><b>Liberação Alf. Front. Origem</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.dt_liberacao_aduana_front_org)}</p>
								<p style={{ fontSize: 12, margin: 0 }}><b>Chegada Alf. Front. Destino</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.dt_entrada_aduana_front_dest)}</p>
								<p style={{ fontSize: 12, margin: 0 }}><b>Liberação Alf. Front. Destino</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.dt_liberacao_aduana_front_dest)}</p>
								<p style={{ fontSize: 12, margin: 0 }}><b>Liberação Fronteira</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_liberacao_fronteira)}</p>
								<p style={{ fontSize: 12, margin: 0 }}><b>Chegada Alf. Destino</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_entrada_aduana_destino)}</p>
								<p style={{ fontSize: 12, margin: 0 }}><b>Liberação Alf. Destino</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_liberacao_aduana_destino)}</p>
							</>}
							<p style={{ fontSize: 12, margin: 0 }}><b>Previsão Descarga</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_previsao_descarga)}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Chegada Descarga</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_inicio_descarga)}</p>
							<p style={{ fontSize: 12, margin: 0 }}><b>Saída Descarga</b>: {DateFormat.formatarDataHoraSqlParaTela(this.state.registroModal.data_termino_descarga)}</p>

							{(!this.state.registroModal.finalizado && this.state.registroModal.cavalo) &&
							<p style={{ fontSize: 14, fontWeight: 'bold', backgroundColor: '#1F3066', color: '#fff', margin: 0, padding: 4, marginTop: 8, marginBottom: 8 }}>Posição</p>}

							{(!this.state.registroModal.finalizado && this.state.registroModal.cavalo) &&
							<p style={{ fontSize: 12, margin: 0 }}><b>Cavalo:</b> {this.state.registroModal.cavalo || ''}</p>}
							{(!this.state.registroModal.finalizado && this.state.registroModal.cavalo) &&
							<p style={{ fontSize: 12, margin: 0 }}><b>Carreta:</b> {this.state.registroModal.carreta || ''}</p>}
							{(!this.state.registroModal.finalizado && this.state.registroModal.cavalo) &&
							<p style={{ fontSize: 12, margin: 0 }}><b>Motorista:</b> {this.state.registroModal.motorista || ''}</p>}
							{(!this.state.registroModal.finalizado && this.state.registroModal.cavalo) &&
							<p style={{ fontSize: 12, margin: 0 }}><b>Lat/Lng:</b>								
								<a target='blank' href={`https://www.google.com/maps/place/${this.state.registroModal.veiculo_latitude},${this.state.registroModal.veiculo_longitude}`}>
									{this.state.registroModal.veiculo_latitude}, {this.state.registroModal.veiculo_longitude}
								</a>
							</p>}
							{(!this.state.registroModal.finalizado && this.state.registroModal.cavalo) &&
							<p style={{ fontSize: 12, margin: 0 }}><b>Local:</b> {this.state.registroModal.veiculo_referencia || ''}</p>}
							{(!this.state.registroModal.finalizado && this.state.registroModal.cavalo) &&
							<Grid cols="12 12 12 12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 8 }}>
								<div style={{ width: "100%", height: window.innerHeight * 0.4 }}>
									<MapLeaflet
										coordenadas={this.props.lista.filter(item => item.solicitacao_carga_id == this.state.registroModal.solicitacao_carga_id && item.cavalo && item.veiculo_latitude && item.veiculo_longitude)
											.map(item => ({
												id: item.solicitacao_carga_id,
												titulo: item.cavalo,
												latitude: item.veiculo_latitude,
												longitude: item.veiculo_longitude,
												direcao: this.state.registroModal.rumo == 'RS' ? 'S' : 'N'
											}))}
										zoom={4}
										latitude={'-28.381875'}
										longitude={'-53.937355'}
										onChange={(coordenadas) => {
										}}
									/>
								</div>
							</Grid>}

						</div>
						{/*<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setState({ ...this.state, registroModal: null })}>Fechar</button>
						</div>*/}
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.reportsCliente.modoTela,
	aguardando: state.reportsCliente.aguardando,
	lista: state.reportsCliente.lista,
	listaFronteiras: state.reportsCliente.listaFronteiras,
	listaNegociadores: state.reportsCliente.listaNegociadores,
	listaRumos: state.reportsCliente.listaRumos,
	listaFarolAtraso: state.reportsCliente.listaFarolAtraso,
	listaOcorrencias: state.reportsCliente.listaOcorrencias,
	listaSolicitacoes: state.reportsCliente.listaSolicitacoes,
	listaPrevisoes: state.reportsCliente.listaPrevisoes,
	listaEventos: state.reportsCliente.listaEventos,
	listaPrevisoesEventos: state.reportsCliente.listaPrevisoesEventos,
	listaOperacoes: state.reportsCliente.listaOperacoes,
	filtro: state.reportsCliente.filtro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, getListaFronteiras, getListaNegociadores, getListaOperacoes, getLista, getListaOcorrencias, registrarAcesso
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ReportsCliente);
