import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function registrarAcesso(tela, menu, tempo) {
		
    return (dispatch, getState) => {
	    axios.post(`${consts.API_URL}/analiseAcesso`, {
			sistema: 'App Cliente',
			tela: tela,
			menu: menu,
			tempo: tempo,
			id_usuario: getState().auth.usuario.id
		}).then(resp => {
		}).catch(e => {
			console.log(e);
		});
    };
}